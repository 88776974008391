<template>
  <div class="Uptype">
    <div class="top-title">
      <div class="top-text">首页类型管理</div>
    </div>

    <div class="Brokerage-box">
      
      <el-form
        ref="list"
        label-width="80px">
        <el-form-item>
          <el-button @click="addDomain()">新增类型</el-button>
        </el-form-item>

<div class="Brokerage-ul">
        <div  v-for="(item, index) in list" :key="item.key"  class="Brokerage-li">
        <el-form-item
          :label="'类型' + (index + 1)"
          :rules="{
             required: true, message: '域名不能为空', trigger: 'blur'
          }"
          >
          <el-input v-model="item.a"></el-input>
        </el-form-item>
        <el-button class="biy-icon" @click.prevent="removeDomain(item)">删除</el-button>
    </div>
              <el-button type="primary" class="biy-icons" @click="tijiao(list)">提交</el-button>
</div>

      </el-form>
    </div>
  </div>
</template>
<script>
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
  export default {
    data() {
      return {
              // 上传图片的地址
      upLoadUrl:config.ImgUrl,
              // 下拉框
      seeks: {},
      list:[ {
        value: ''
      }],


      };
    },
      created() {
    this.getSong()
  },
    methods: {
        // 查询接口
      async getSong(){
      let res = await apiurl.baseConfigDAL({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
            handleMode:'get',
            cfgCatalog:'RecruitWorkType', })
            if(this.$common.verifyAjaxResult(res,false)==false) return;
            this.list = res.Result.list
      },

  // 删除
      removeDomain(item) {
        var index = this.list.indexOf(item)
        if (index !== -1) {
          this.list.splice(index, 1)
        }
      },
      // 新增按钮
      addDomain() {
        if (this.list.length < 30) {
           this.list.push({
          value: '',
          key: Date.now()
        });
        }else{
           this.$alert('不能添加更多了', '消息提示', {confirmButtonText: '确定'});
        }
      },

      //弹窗
      tijiao(e){
             this.$confirm('是否确认提交', '消息提示', {
                         distinguishCancelAndClose: true,
                         confirmButtonText: '确认',
                         cancelButtonText: '取消操作'
                       }).then(() => {
                         this.tijiaos()
                         }).catch(action => {
                           console.log("取消");
                         })

      },
   // 提交接口
    async tijiaos(){

       let listdo = [] 
        for (let i = 0; i < this.list.length; i++) {
          const lists = this.list[i];
          console.log(lists.a);
          if (lists.a !==undefined && lists.a !=='') {
              listdo.push({'a':lists.a,'key':lists.key,'value':''});     
          }
        }

      	let res = await apiurl.baseConfigDAL({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
            handleMode:'set',
            cfgCatalog:'RecruitWorkType',
            cfgData:{
              list:listdo
            } 
            });
               
         if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('更改成功', '消息提示', {confirmButtonText: '确定'});
         this.getSong()
    },
    }
  }
</script>

<style  scoped>
/* 搜索栏样式 */
.top-title {
  width: 94%;
  margin: 0 auto;
}
.top-text {
  height: 30px;
  font-size: 18px;
}
.Brokerage-box {
  width: 100%;
  margin: 100px auto;
}
.Brokerage-boxs {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.Brokerage-ul{
  width: 100%;
    display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.Brokerage-li{
   width: 350px;
  height: 70px;
    display: flex;
  justify-content: left;
}

/* 搜索按钮 */

.biy-icon {
  width: 80px;
  height: 36px;
  line-height: 36px;
  padding: 0;
  margin-left: 0px;
  background-color: #01847f;
  color: #fff;
}
.biy-icons {
  width: 100px;
  height: 36px;
  line-height: 36px;
  padding: 0;
  margin-left: 0px;
  color: #fff;
  background-image: linear-gradient(to right,  #3ecc7c , #28cfb3);
}
</style>